import React from 'react'
import JournalsPage from 'src/components/Page/Author/Journals'



const JournalsPageContainer = ({ location }) => {
    return <JournalsPage location={location} />
}

export default JournalsPageContainer

